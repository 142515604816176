#prettyvc-select-harness {
  position: relative;
}

.prettyvc-renderer * {
  font-family: inherit;
}

.prettyvc-stage {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.prettyvc-block[contenteditable]:focus {
  border: none;
  outline: none;
  cursor: text!important;
}

@keyframes append-animate {
  from {
    outline: 1px dashed transparent;
  }

  to {
    outline: 1px dashed #ff0000;
  }
}

.prettyvc-stage-dragbox {
  animation: append-animate .5s ease-in-out;
  cursor: move;
}

/*
  A4 Size:
  297mm x 210mm
*/
.prettyvc-a4-landscape {
  width: 100em;
  height: 70.7070707em;
}

.prettyvc-a4-portrait {
  height: 100em;
  width: 70.7070707em;
}

/*
  Credit Card Size:
  85.6mm x 53.98mm
*/
.prettyvc-creditcard-landscape {
  width: 28.821548822em;
  height: 18.175084175em;
}

.prettyvc-creditcard-portrait {
  height: 85.6em;
  width: 53.98em;
}

@media only screen and (max-width: 1300px) {
  .prettyvc-scale-auto {
    font-size: 6px;
  }
}

@media only screen and (max-width: 1080px) {
  .prettyvc-scale-auto {
    font-size: 4px;
  }
}

@media print {
  .prettyvc-stage {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .prettyvc-stage .prettyvc-scale-800 {
    font-size: 11px !important;
  }

  #launcher {
    display: none!important;
  }
}
