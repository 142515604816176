a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.rdw-editor-toolbar {
  border: none;
  padding: 0;
}

.public-DraftEditor-content,
.rdw-editor {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  line-height: 1.25rem;
}

.rdw-dropdown-wrapper,
.rdw-dropdown-wrapper:hover,
.rdw-block-dropdown,
.rdw-block-dropdown:hover,
.rdw-option-wrapper:hover,
.rdw-option-wrapper {
  box-shadow: none;
  border: none;
}

.rdw-option-wrapper.rdw-option-active:active,
.rdw-option-wrapper.rdw-option-active:hover,
.rdw-option-wrapper.rdw-option-active {
  box-shadow: none;
  border-radius: 8px;
}

.rdw-option-wrapper.rdw-option-active {
  background: #FAFAFA;
}
